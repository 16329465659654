.form-group label {
    display: block;
}

.bg-hr {
    background-color: #D9D9D9;
}

.bg-header {
    background-color: #C7C7C7;
}

.bg-sidemenu {
    background-color: #DADADA;
}

.color-icons-sidemenu {
    color: #222222;
}

.bg-color-heading {
    background-color: #00549E;
}

.bg-retain-button {
    background-color: #E91010;
    color: white;
    font-weight: bolder;
    border: none;
    font-size: 8px;
}

.heading-blue {
    color: #00549E;
}

.text-light-blue {
    color: rgba(0, 84, 158, 0.5);
}

.card-headers {
    background-color: white;
}

table.table {
    thead {
        // background: #fff;
        color: #fff !important;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(156, 39, 176, .4);

        th {
            color: #fff;
        }
    }

}

.import_tab {
    li {
        a.nav-link {
            margin: 2px;
            background: white !important;
            color: black;
            font-weight: 600;
            padding: 7px 23px;
            text-transform: uppercase;

            &:hover {
                color: #00549e !important;
                background-color: white !important;
            }
        }

        a.nav-link.active {
            border: none;
            background-color: white !important;
            border-bottom: 2px solid #00549e !important;
            color: #00549e !important;
        }
    }
}

// .react-bootstrap-table table thead tr {
//     //here is the card header background color change
//     background-color: #C2C2C2;

//     th {
//         color: black;
//     }
// }

.title-small-call-management-heading {
    color: #00549E;

}

.CallHistoryTable-CM-Body tr td {
    font-weight: normal;
}

.payment-details {
    background: #FFFFFF;
    border: 3px solid #DADADA;
    border-radius: 10px;
    margin-top: 23px;
    margin-bottom: 32px;
    padding: 15px;
    width: 90%;

    .payment-button {
        border: 0.1rem solid #d9d9d9;
        border-radius: 2rem;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        color: #00549E;
        background-color: white;
        padding: 5px;
        text-align: center;
    }

    p {
        text-align: start;
        margin-left: 24px;
        font-size: 14px;
    }

    .detail {
        font-weight: bold;
    }

    .payment-sections {
        border-right: 2px solid #D9D9D9;
    }
}

.loan-update-input-headings {
    font-weight: normal !important;
    font-weight: 400;
    font-size: 15.0857px;
    line-height: 18px;
    color: #222222;
}

.title-small-call-management {
    font-size: 14px;

}

.title-above-small-call-management {
    font-size: 16px;
}

.loan-update {
    display: flex;
    min-width: 90%;

    h5 {
        font-weight: 700;
        font-size: 21.6464px;
        line-height: 26px;
        color: #222222;
        margin-top: 25px;

    }
}

.title-large-disposition-call-management {
    font-size: 20px;
}

.cm-last-table-header * {
    font-weight: bold !important;
    color: black !important;
    font-size: 16px !important;
}

.call-buttons-call-management * {
    margin: 10px;
}

.call-management-columsn-loan-update {
    background-color: rgba(217, 217, 217, 0.3);
    ;
}


@media (min-width:768px) and (max-width: 1300px) {
    .d-flex {
        display: flex !important;
    }

    .borrowerProfileData {
        display: grid !important;
    }

    .disposition-class-CM {
        width: 100px !important;
    }

    .disposition-code-CM {
        width: 100px !important;
    }

    .top-box-col-call-management {
        width: 15%;
    }

    // .fist-half-last{
    //     border: 1px solid red;
    //     display: flex;
    //     justify-content: space-between;
    // }
    // .secondHalf-mobile{
    //     height: 50%;
    // }
    .parent-fourth-box-callmanagement {
        // display: grid;
        display: flex;
        grid-template-rows: auto auto;
    }

    .third-box-call-management {
        display: grid !important;
    }

    .firstHalf-mobile {
        width: 100% !important;
    }

    // .second-half-last{
    //     width: 10%;
    //     div{
    //         height: 100%;
    //     }

    // }

    .loan_Number_box-call-management {
        margin-left: 0 !important;
    }

    .first-half-calling-call-management {
        width: 70% !important;
        max-height: 300px !important;
    }

    .mobile-radio-select {
        display: grid !important;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;

    }

    .outer-call-buttons-holder-call-management {
        display: flex !important;
        justify-content: center !important;
    }

    .call-buttons-call-management {
        display: flex !important;
        width: 50% !important;
        align-items: center;
        justify-content: space-between;
    }

    .comments-holder-call-management {
        display: flex !important;
        justify-content: space-between;

        button {
            padding-top: 2%;
            padding-bottom: 2%;
        }

        align-items: center;
    }


}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: yellow;
}

.CustomClass {
    background-color: rgb(239, 238, 238);
    width: 126px;
    height: 50px;
    padding: 16px, 0px, 16px, 0px;
    // box-shadow: #090909;
    margin-top: -5px;
    margin-bottom: 100px;
    // border: 3px inset #e9ecef;
    // border-bottom: 1.2px inset #c6c7c7;
    // border-top: 1.2px inset #c6c7c7;
    text-transform: uppercase !important;
    // color: #222222 !important;
    // text-decoration-color: #222222 !important;
}

input {
    text-indent: 10px;
}

 

/**
Width
126px
Height
56px
Padding
16px, 0px, 16px, 0px
*/
.CustomBodyClass tr {
    
}

.CustomBodyClass tr:nth-child(even) {
    
}

// .CustomBodyClass tr:nth-child(odd) {
//     background-color: #ffffff;
//     // border: #00549E;
// }
// .CustomBodyClass tr:nth-child(odd) {
//     background-color: #ffffff;
//     // border: #00549E;
// }
.CustomBodyClass tbody tr td {
    // color: background: #000000;
    // backgroundColor: rowIdx % 2 === 0 ? 'red' : 'blue
    // filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25)); 
 
}

.CustomBodyClass tr td {
     
}

.classButton {
    font-family: sans-serif;
    font-style: normal;
    background: #00549E;
    color: white !important;
    width: 100%;
    height: 36px !important;
    text-decoration-color: white !important;
    font-weight: 1200 !important;
    font-size: 18.7686px !important;
    line-height: 25px;
    padding: -0.00rem !important;
    margin-top: 8% !important;
    align-items: center;
    text-align: center;

    // vertical-align: top;
}


.outlineColor {
    // .btn-outline-primary {
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18.7686px;
    // line-height: 25px;
    // width: 80%;
    height: 36px !important;
    color: #00549E !important;
    background-color: #ffffff !important;
    border: 2px solid #00549E !important;
    line-height: 25px;
    padding: -0.00rem !important;
    // height: 27px;
    margin-top: 5% !important;
    text-transform: capitalize;
    align-items: center;
    text-align: center;
    // font-size: 20px;
    // }
}

.setAgentProfile {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #00549E !important;
}

.CustomBodyClass {
    text-align: left;
    // box-shadow: #090909;
    color: #222222 !important;
}

.CustomBodyClass tr td {
    color: #222222;
    font: Roboto;
    font-weight: 519;
    size: 18px;
    line-height: 21.09px;
}

.CustomBodyClass-CM tr td {
    height: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16.924px;
    line-height: 20px;
    /* identical to box height */


    color: #222222;
}

.searchClass {
    width: 100%;
    display: block;
    margin: inherit;
    line-height: 1.5;
    font-size: .875rem;
    // padding: 0.5rem 1.6rem;
    // font-size: .64rem;
}

//#

.setColor {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #222222;
}

.btn {
    text-transform: capitalize;
}

.title {
    color: #00549E;
    font-size: 24px;
    font-weight: 700;

}

.setgap {
    row-gap: 1rem;
}

.table-dark {
    background-color: #A6A8AA !important;
}

.disposition_codes {
    // This class is for the disposition code extention inputs
    background-color: #F4F4F4;

    ::placeholder {
        color: black;
    }
}
input::placeholder{
    font-size: 12px;
}

.font-loanDetails {
    font-weight: 700;
}

// .form-control-sm2 {
// 	padding: .25rem .5rem;
// 	font-size: .76563rem;
// 	line-height: 1.5;
// 	border-radius: .2rem;
// 	min-height: 20px;
// }
* {
    font-family: sans-serif !important;
}

.CustomClass {
    padding-bottom: 40px !important;
}


 

.call_mage_label2 {
    // position: absolute;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16.7686px;
    line-height: 25px;
    text-align: left;
    display: block;
    margin-bottom: 2px;
    margin-top: -14px
}

.form-control {
    width: 100% !important;
}

thead>tr>th {
    vertical-align: middle !important;
}

.react-bs-table-tool-bar {
    margin: 10px 10px 0px 10px;
}

::-webkit-scrollbar {
    position: absolute;
    height: 8px;
    border-radius: 0;
    background-color: rgba(217, 217, 217, 0.2);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0);
    //background: rgba(217, 217, 217, 0.9);
    //box-shadow:  0px -1px 4px rgba(0, 0, 0, 0.25);


}

::-webkit-scrollbar-thumb {
    border-radius: 2px;

}

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(217, 217, 217, 0.2);
}

//  ::-webkit-scrollbar-button{
//     background-image: url("../../public/assets/");
//     padding: 6px;
//     border: 1px solid #EBEBEB;
//     background-color: #EBEBEB;
//     width: 25px;
//     height: 15px;
//     background-repeat: no-repeat;
//     box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
//     margin: 10px;
//  }


.btn2 {
    padding: 0.4rem 1.00rem !important;
    border-radius: 4rem !important;
    background-color: #FFFFFF;
    color: #00549E;
}

.btn2:focus {
    border: 1px solid #00549E;
}

.line {
    width: "10px";
    background-color: #D9D9D9;
    // backgroundColor:"";
    margin-right: 25px;
    //  marginRight:"100px"
}

hr {
    flex: 1;
    border: none;
    height: 1px;
    background: black;
}

.react-bs-table-sizePerPage-dropdown {
    visibility: hidden !important;
}
/*
ul.pagination {
    display: inline-block !important;
    padding: 0 !important;
    margin: 0 !important;
    
}

ul.pagination li {
    display: inline !important;
}


ul.pagination li a {
    color: rgba(87, 87, 87, 0.72) !important;
    float: left !important;
    padding: 8px 16px !important;
    text-decoration: none !important;
    transition: background-color .3s !important;
    border: 1px solid #ddd !important;
    border-radius: 15%;
}

ul.pagination li a.active {
    background-color: #4CAF50 !important;
    color: white !important;
    border: 1px solid #4CAF50 !important;
}
*/
// this line needs to be at end always
select {
    // border-right: 19px solid transparent !important;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../../DownArrow.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: calc(100% - 11px) center;
  }
input[type=date]::-webkit-calendar-picker-indicator{
       color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("../../DownArrow.svg") no-repeat;
    width: 12px;
    background-size: 12px;
    background-position: calc(100% - 3px) center;
    height: 16px;
    border-width: thin;
}

// .CustomClass th:nth-child(8){
//     width: "25%" !important;
// }

.CustomBodyClass tr td:nth-child(8){
   max-width: 100px!important;
   overflow: hidden!important;
}

.CustomBodyClass tr td:nth-child(8):hover{
    max-width: fit-content!important;
 }
::-webkit-input-placeholder {
    opacity:  0.4 !important;
  }
  input[type=date]::-webkit-datetime-edit-text,
  input[type=date]::-webkit-datetime-edit-month-field,
  input[type=date]::-webkit-datetime-edit-day-field,
  input[type=date]::-webkit-datetime-edit-year-field {
    opacity: 0.4;
    // color: #888;
  }
  input[type=date].date-input--has-value::-webkit-datetime-edit-text,
input[type=date].date-input--has-value::-webkit-datetime-edit-month-field,
input[type=date].date-input--has-value::-webkit-datetime-edit-day-field,
input[type=date].date-input--has-value::-webkit-datetime-edit-year-field {
//   color: #f8f9fa;
  opacity: 0.4;
}
// input[type="date"]::-webkit-calendar-picker-indicator {
//     color: rgba(0, 0, 0, 0);
//     opacity: 1;
//     display: block;
//     background: url("../../DownArrow.svg") no-repeat;
//     width: 20px;

//     height: 20px;
//     border-width: thin;
// }
.marginClass{
    margin-top: 7%;
}
*{
    font-family: sans-serif !important;
    // font-weight: 100;
}

.searchBarViewClient::placeholder{
    opacity: 1;
}
