.iframe-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border: 1px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  background-color: #fff;
  transition: width 0.3s, height 0.3s, bottom 0.3s, right 0.3s;
  overflow: hidden; /* Ensures content fits within the container */
}

.iframe-container.minimized {
  height: 30px;
  width: 200px;
}

.iframe-container.maximized {
  height: 80%;
  width: 50%;
  bottom: 0;
  right: 0;
}

iframe {
  width: 100%;
  height: calc(100% - 30px);
  border: none;
}

.header {
  height: 30px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
}

.header .title {
  font-size: 14px;
}

.header .controls {
  display: flex;
  align-items: center;
}

.header .controls span {
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}
